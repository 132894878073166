import _typeof from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.to-string.js";
import { unref as _unref, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7eeab762"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onClick"];
var _hoisted_2 = ["src"];
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
import { reactive, computed, onMounted, inject } from 'vue';
import { Play } from "@/assets/SVGs/AutoTuneIconLib";
export default {
  props: {
    media: {
      type: Array,
      default: function _default() {
        return [];
      },
      validator: function validator(value) {
        if (value.length === 0) {
          return true;
        }

        return value.every(function (item) {
          return _typeof(item) === 'object' && typeof item.image_source === 'string';
        });
      }
    },
    gui: {
      type: String,
      default: ''
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var videoModal = inject('videoModal');
    var state = reactive({
      selectionIndex: 0
    });
    /**
     * Returns the currently selected media object based on the value of state.selectionIndex.
     * @returns {Object}
     */

    var selectedMediaObject = computed(function () {
      var media = props.media,
          gui = props.gui;

      if (!media.length && gui.length) {
        return {
          image_source: gui
        };
      }

      return media[state.selectionIndex];
    });
    /**
     * Handles the click event for the previous image button.
     */

    var handlePrevImg = function handlePrevImg() {
      if (state.selectionIndex !== 0) {
        state.selectionIndex -= 1;
      }
    };
    /**
     * Handles the click event for the next image button.
     */


    var handleNextImg = function handleNextImg() {
      if (state.selectionIndex !== props.media.length - 1) {
        state.selectionIndex += 1;
      }
    };

    var handleVideo = function handleVideo(_ref) {
      var title = _ref.title,
          url = _ref.url;

      if (url && videoModal.value && typeof videoModal.value.openVideoModal === 'function') {
        videoModal.value.openVideoModal({
          title: title,
          url: url
        });
      }
    };

    onMounted(function () {});
    return function (_ctx, _cache) {
      var _component_el_image = _resolveComponent("el-image");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(), _createBlock(_component_el_col, {
        class: "media-carousel-wrapper"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_row, {
            align: "middle",
            justify: "center",
            class: "std-padding_line-md"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(function () {
                  var _unref3, _unref4;

                  return [_createElementVNode("div", {
                    class: "active-media-wrapper",
                    onClick: _cache[0] || (_cache[0] = function ($event) {
                      var _unref2;

                      return handleVideo({
                        title: '',
                        url: (_unref2 = _unref(selectedMediaObject)) === null || _unref2 === void 0 ? void 0 : _unref2.video_source
                      });
                    })
                  }, [_createVNode(_component_el_image, {
                    class: "active-image",
                    src: _unref(selectedMediaObject).image_source,
                    fit: "cover",
                    style: _normalizeStyle({
                      cursor: (_unref3 = _unref(selectedMediaObject)) !== null && _unref3 !== void 0 && _unref3.video_source ? 'pointer' : 'default'
                    })
                  }, null, 8, ["src", "style"]), (_unref4 = _unref(selectedMediaObject)) !== null && _unref4 !== void 0 && _unref4.video_source ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    class: "play-btn flex-centered play-icon"
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_unref(Play))];
                    }),
                    _: 1
                  })) : _createCommentVNode("", true)])];
                }),
                _: 1
              })];
            }),
            _: 1
          }), props.media.length > 1 ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            align: "middle",
            justify: "center"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_col, {
                span: 18
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_row, {
                    align: "middle",
                    justify: "start",
                    class: "thumbnails"
                  }, {
                    default: _withCtx(function () {
                      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.media, function (item, i) {
                        return _openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["thumbnail", {
                            active: i === _unref(state).selectionIndex
                          }]),
                          key: i,
                          onClick: function onClick($event) {
                            return _unref(state).selectionIndex = i;
                          }
                        }, [_createElementVNode("img", {
                          src: item.thumbnail_source,
                          fit: "cover"
                        }, null, 8, _hoisted_2), item !== null && item !== void 0 && item.video_source ? (_openBlock(), _createBlock(_component_el_icon, {
                          key: 0,
                          class: "thumbnail-play-btn flex-centered play-icon"
                        }, {
                          default: _withCtx(function () {
                            return [_createVNode(_unref(Play))];
                          }),
                          _: 1
                        })) : _createCommentVNode("", true)], 10, _hoisted_1);
                      }), 128))];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _createVNode(_component_el_col, {
                span: 6
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_row, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_el_col, {
                        span: 24,
                        class: "control-buttons-wrapper flex-row flex-row-end"
                      }, {
                        default: _withCtx(function () {
                          return [_createElementVNode("button", {
                            class: "prev-button",
                            onClick: handlePrevImg
                          }, [_createVNode(_component_el_icon, {
                            class: _normalizeClass(["controls", {
                              disabled: _unref(state).selectionIndex === 0
                            }])
                          }, {
                            default: _withCtx(function () {
                              return [_createVNode(_unref(ArrowLeftBold))];
                            }),
                            _: 1
                          }, 8, ["class"])]), _createElementVNode("button", {
                            class: "next-button",
                            onClick: handleNextImg
                          }, [_createVNode(_component_el_icon, {
                            class: _normalizeClass(["controls", {
                              disabled: _unref(state).selectionIndex === props.media.length - 1
                            }])
                          }, {
                            default: _withCtx(function () {
                              return [_createVNode(_unref(ArrowRightBold))];
                            }),
                            _: 1
                          }, 8, ["class"])])];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          })) : _createCommentVNode("", true)];
        }),
        _: 1
      });
    };
  }
};